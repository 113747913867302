body {
	margin: 0;
	font-family: 'Questrial', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background-color: #F0F0F0;
	background-size: 64px 64px;
	background-image:
		linear-gradient(to right, #D2D2D2 1px, transparent 1px),
		linear-gradient(to bottom, #D2D2D2 1px, transparent 1px);
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}